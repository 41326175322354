import {useEffect, useState,useContext} from "react";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import {GeneralesContext} from '../context/GeneralesContext'
import { Box, Button, Card, CardContent, Grid, IconButton, 
    LinearProgress, Paper, Tooltip, Typography,TextField,
    Badge, 
} from "@material-ui/core";
import TablaNotificacion from "./TablaNotificacion";
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import Galeria from "./Galeria";
import Relojes from "./Relojes";
import {AddCircleOutlined} from "@material-ui/icons";
import ModalNvoEdit from "./ModalNvoEdit";
import ModalAgregarEvidencia from "./ModalAgregarEvidencia";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { obtenDispo } from '../funciones/IdDispositivo';
import io from "socket.io-client";
import {toast} from "react-toastify";
import moment from "moment";
const ENDPOINT = "https://redvargas-socket.daduva.mx:6001";

const useStyles = makeStyles((theme) => ({}));

const listTpDet=[{id:1, detalle:"ALL",cadena:"Todos"},
                 {id:2, detalle:"REG",cadena:"Circunscripción"},
                 {id:3, detalle:"EDO",cadena:"Estado"},
                 {id:4, detalle:"FED",cadena:"Dto Federal"},
                 {id:5, detalle:"LOC",cadena:"Dto Local"},
                 {id:6, detalle:"MUN",cadena:"Municipio"},
                 {id:7, detalle:"SEC",cadena:"Sección"},
                 {id:8, detalle:"PRO",cadena:"Promotor"},
                 {id:9, detalle:"ORG",cadena:"Organización"},
                 {id:10, detalle:"SUB",cadena:"Usuario Especifico"},]
    const clasePRueba=()=>{
        let i=0,d=2
        return i+d
    }
const Notificacion = () => {
    const classes = useStyles();
   
    const socket = io(ENDPOINT,  {transports: ['websocket']});//se conecta     
    const idusu = localStorage.getItem("UsuId")?parseInt(localStorage.getItem("UsuId")):0;    
    const usuTpEstr = localStorage.getItem("usuTpEstr")?parseInt(localStorage.getItem("usuTpEstr")):0;    
    const usuIdBas = localStorage.getItem("usuIdBas")?parseInt(localStorage.getItem("usuIdBas")):0;    
    const auxDispV =obtenDispo() 
    const history = useHistory();
    const source = axios.CancelToken.source();
    const [fchasSelect, setFchasSelect] = useState({ inicio: new Date(), final: new Date()});
    const {generales, guardarGenerales} = useContext(GeneralesContext);
    const [selectedID, setSelectedID] = useState(null);
    const [recorrido, setRecorrido] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [cargando, setCargando] = useState(false);    
    const [abrirModalAgregar, setAbrirModalAgregar] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [buscar, setBuscar] = useState(true);
    const [checked, ] = useState(false);
    const [abrirModal, setAbrirModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [espera, setEspera] = useState(false);
    const [cadena, setCadena] = useState("")    
    const [listDetalle, setListDetalle] = useState([])    
   
    const [cantidadNoti, setCantidadNoti] = useState(0)  
    const [listEstruc,setListEstruc] = useState([])
    const [lastNotif,setLastNotif] = useState(0)

    useEffect(() => {          
        guardarGenerales({...generales, Titulo: 'Notificaciones'})
        getListEstrucTipo()
        clasePRueba()
        return ()=> {
            source.cancel();
        }  
    }, []);

    useEffect(() => {
        setGaleria([]);       
        if (selectedID !== null) {
            consultarImagenes();
            getListDetalle(selectedID)
        }
    }, [selectedID,update]);
    
    useEffect(()=>{    
    
        socket.on("NotificacionEmit", data => {                
            const info=JSON.parse(data)
            console.log(lastNotif,parseInt(info.Key ),info);
            if ( lastNotif !== parseInt(info.Key )
                && ( usuTpEstr===1|| (usuIdBas===info.IdBase && filtrar(info.Det) ) ) ) 
            {
                setCantidadNoti(cantidadNoti+1)   
                const fch= moment(info.Fecha).format("YYYY-MM-DD hh:mm a")
                const mensaje=`Se registro una nueva notificación, ${fch}` 
                toast.info(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `socket-notifi${info.Key}`
                })
            }            
        });

         socket.on("NotificacionVerificaEmit", data => {                
            const info=JSON.parse(data)
            console.log("socket verifica emit",info);
            if (selectedID.Id===info.NotF) {
                
                const fch= moment(info.Fecha).format("YYYY-MM-DD HH:mm")
                const mensaje=`Se registro una nueva lectura en la  notificación, ${fch}` 
                toast.info(mensaje, {
                    pauseOnFocusLoss: false,
                    toastId: `lectura-notifi${info.Key}`
                })
                setSelectedID({...selectedID,Avance:info.Avance})
               // console.log(mensaje);
               //getListDetalle(selectedID)
            }
            
            
        });

        return () => socket.disconnect();   
    })
  
    function filtrar(cadena) {
        const filtro = listTpDet.filter(( tipo)=>tipo.id=== cadena)
        let bandera="false"
        if (filtro.length!==0) {
            bandera="true"
        }
        return bandera
    }
    const getListEstrucTipo = () => {               
        const data = qs.stringify({
            usuario:idusu  ,   
            idDispositivo:auxDispV,
        });   
        const url = "usuario/v2/estructura-tipo-list";
        function respuesta(aux) {
            if (aux.length !== 0) 
            {   /* let filtro=[]
                aux.forEach(element => {
                    if (element.Id!==1) {
                        filtro.push(element)
                    }
                }); */
                setListEstruc(aux)   
            }       
        }    
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
    } 

    const getListDetalle = (llave) => {
        const data = qs.stringify({
            usuario: idusu,
            status: 0,
            llave: llave.Id,
            idDispositivo: auxDispV,
        });
        const url = "notificacion/v1/det/list";

        function respuesta(response) {
            if (response[0].Id !== -1) {
                let nvo=[]
                for (let index = 0; index < 10; index++) {
                    nvo.push(response[index]??response[0]);
                    
                }
                setListDetalle(response)
            }
            else{
                setListDetalle([])
            }
        }
    
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const consultarImagenes = () => {
        const data = qs.stringify({
            llave: selectedID.Id,
            usuario: idusu,
            idDispositivo: auxDispV,
        });
        const url = "notificacion/v1/file/list";

        function respuesta(response) {
            if (response[0].Id === -1) {
                setGaleria([]);                
                setMensaje("Sin fotografías.");
            } 
            else {
                let nvo=[]
                for (let index = 0; index <10; index++) {
                    nvo.push(response[index]??response[0]);
                    
                }
                setGaleria(response);
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const buscarDatos = () => {
        setBuscar(!buscar)
    }

    const recargar = () => {
        setUpdate(!update)
    }

    const modalAgregarEvidencia = abrirModal ? <ModalAgregarEvidencia abrirModal={abrirModal}
                                                setAbrirModal={setAbrirModal} recorrido={recorrido}
                                                selectedID={selectedID} idusu={idusu}  setUpdate={recargar}
                                            /> : null

    const modalAgregarRecorrido = abrirModalAgregar ? <ModalNvoEdit abrirModal={abrirModalAgregar}
                                                        setAbrirModal={setAbrirModalAgregar}
                                                        idusu={idusu} history={history}
                                                        actualiza={buscarDatos} setLastNotif={setLastNotif}
                                                        titulo="Nueva Notificación"
                                                       listEstruc={listEstruc}
                                                    /> : null

    const agregarDatos = () => {
        setAbrirModalAgregar(true)
    }

    const listarGaleria =
        galeria.length > 0 || listDetalle.length>0? (
            <Galeria listDetalle={listDetalle}
                galeria={galeria} regis={selectedID}
            />
        ) : (
            <Paper className={classes.root}>
                <Typography variant={"h5"}>{mensaje}</Typography>
            </Paper>
        );


    const handleAbrir = () => {
        setAbrirModal(true)
    }

    const onChange = e => {
        setCadena(  e.target.value )
    }

    const principal = () => {
        return (
        <Grid container spacing={1}>
            {modalAgregarRecorrido}
            <Grid item xs={12}>
                <Card>
                <CardContent style={{padding:0.4+'rem'}} >
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item>
                            <Tooltip title={'Buscar'}>
                                <IconButton
                                    color="primary" aria-label="Buscar"
                                    onClick={buscarDatos}
                                >
                                    <SearchIcon/>
                                </IconButton>
                            </Tooltip>
                        </Grid>    
                        <Grid item>
                            <Relojes
                                fchasSelect={fchasSelect}
                                setFchasSelect={setFchasSelect}
                            />
                        </Grid>          
                        <Grid item>
                            <TextField id="cadena-Txt" name="cadena" label="Filtro" fullWidth size="small"
                                value={cadena} onChange={onChange} variant="outlined"/>                                                        
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Agregar Notificacion'}>
                                <IconButton
                                    color="primary" onClick={agregarDatos}
                                    aria-label="Agregar Notificacion"                                    
                                >
                                    <AddCircleOutlined/>
                                </IconButton>
                            </Tooltip>
                        </Grid>     
                        <Grid item>
                             
                            <Badge color="secondary" badgeContent={cantidadNoti} invisible={false}>
                                <NotificationsIcon />
                            </Badge>
                            
                        </Grid>                   
                    </Grid>
                </CardContent>
                </Card>
            </Grid>
            <Grid item xs={9}>
                <TablaNotificacion
                    history={history} setRecorrido={setRecorrido}                    
                    selectedID={selectedID} setSelectedID={setSelectedID}
                    source={source} idusu={idusu} buscar={buscar}
                    checked={checked} listMun={[]} actualiza={buscarDatos}
                    cadena={cadena} 
                    fchasSelect={fchasSelect}
                />
            </Grid>
            <Grid item xs={3}>
                {modalAgregarEvidencia}
                {recorrido.Obsrv ? 
                    cargando ? (
                        <Box mt={2} mb={2} width="80%">
                            <LinearProgress/>
                        </Box>
                    ) 
                    : (
                        <Grid container>
                        <Grid item xs={12} style={{paddingBottom: 10}}>
                            <Typography color="primary">
                                Notificación
                            </Typography>
                            <Typography>
                                {recorrido?.Obsrv}
                            </Typography>
                        </Grid>
                        </Grid>
                    ) 
                :null}
                {selectedID !== null ? 
                <Grid container>
                    <Grid container item xs={12}  direction="row" style={{paddingBottom: 10,}}>
                        <Button
                            variant="contained" color="primary"
                            startIcon={<AddCircleOutlined/>}
                            onClick={handleAbrir} size="small"
                        >
                            Subir Fotos
                        </Button>
                        <Grid item xs={4} style={{marginTop:".3rem", marginLeft:".3rem"}}>
                            <Typography variant="body2" style={{width:"5rem"}}>
                                {`Avance ${selectedID.Avance} / ${selectedID.Total}`}
                            </Typography>
                        </Grid>
                        
                    </Grid>
                </Grid> 
                : null  }
                {cargando ? (
                    <Box pl={1} pt={5}>
                        <LinearProgress/>
                    </Box>
                ) : (
                    listarGaleria
                )}
            </Grid>
        </Grid>
        );
    };

    return <div>{principal()}</div>;
};

export default Notificacion;
